const canvasCone = document.getElementById('coneCanvas');
const ctxCone = canvasCone.getContext('2d');

let baseConePointRadius; // Radius of the point
const rotationConeSpeed = 0.005; // Rotation speed
let angleCone = 0;

const pointsCone = [];
const smallPointsCone = [];
let numConeLatitudeLines; // Number of latitude lines for large cone
let numConeLongitudeLines; // Number of longitude lines for large cone
let smallNumConeLatitudeLines; // Number of latitude lines for small cone
let smallNumConeLongitudeLines; // Number of longitude lines for small cone
let coneHeight; // Height for large cone
let coneRadius; // Radius for large cone
let smallConeHeight; // Height for small cone
let smallConeRadius; // Radius for small cone
let layersToRemove; // Number of layers to remove from the bottom for large cone
let smallLayersToRemove; // Number of layers to remove from the bottom for small cone

function calculateConePoints() {
	pointsCone.length = 0; // Clear existing points
	for (let i = 0; i <= numConeLatitudeLines - layersToRemove; i++) {
		const height = coneHeight * (i / (numConeLatitudeLines - layersToRemove));
		const radius = coneRadius * (1 - i / numConeLatitudeLines);
		for (let j = 0; j <= numConeLongitudeLines; j++) {
			const theta = j * 2 * Math.PI / numConeLongitudeLines;
			const x = radius * Math.cos(theta);
			const z = radius * Math.sin(theta);
			pointsCone.push({ x, y: height, z });
		}
	}
}

function calculateSmallConePoints() {
	smallPointsCone.length = 0; // Clear existing points
	for (let i = 0; i <= smallNumConeLatitudeLines - smallLayersToRemove; i++) {
		const height = smallConeHeight * (i / (smallNumConeLatitudeLines - smallLayersToRemove));
		const radius = smallConeRadius * (1 - i / smallNumConeLatitudeLines);
		for (let j = 0; j <= smallNumConeLongitudeLines; j++) {
			const theta = j * 2 * Math.PI / smallNumConeLongitudeLines;
			const x = radius * Math.cos(theta);
			const z = radius * Math.sin(theta);
			smallPointsCone.push({ x, y: height, z });
		}
	}
}

function resizeConeCanvas() {
	baseConePointRadius = 1.5;
	numConeLatitudeLines = 10;
	numConeLongitudeLines = 20;
	smallNumConeLatitudeLines = 8;
	smallNumConeLongitudeLines = 16;
	layersToRemove = 2;
	smallLayersToRemove = 1;
	canvasCone.width = window.innerWidth;
	canvasCone.height = window.innerHeight;
	// coneHeight = canvasCone.height * 0.5;
	coneHeight = 400;
	// coneRadius = canvasCone.width * 0.4;
	coneRadius = 700;
	smallConeHeight = coneHeight / 1.1;
	smallConeRadius = coneRadius / 1.1;

	if ( window.matchMedia( '(min-width: 1200px)' ).matches ) {
		baseConePointRadius = 1.5;
		numConeLatitudeLines = 10;
		numConeLongitudeLines = 20;
		smallNumConeLatitudeLines = 8;
		smallNumConeLongitudeLines = 16;
		layersToRemove = 2;
		smallLayersToRemove = 1;
		coneHeight = 400;
		coneRadius = 700;
		smallConeHeight = coneHeight / 1.1;
		smallConeRadius = coneRadius / 1.1;
	} else if ( window.matchMedia( '(min-width: 768px) and (max-width: 1199px)' ).matches ) {
		baseConePointRadius = 1.5;
		numConeLatitudeLines = 8;
		numConeLongitudeLines = 16;
		smallNumConeLatitudeLines = 5;
		smallNumConeLongitudeLines = 10;
		layersToRemove = 2;
		smallLayersToRemove = 1;
		coneHeight = 350;
		coneRadius = 500;
		smallConeHeight = coneHeight / 1.1;
		smallConeRadius = coneRadius / 1.2;
	} else if ( window.matchMedia( '(max-width: 768px)' ).matches ) {
		baseConePointRadius = 1;
		numConeLatitudeLines = 7;
		numConeLongitudeLines = 14;
		smallNumConeLatitudeLines = 5;
		smallNumConeLongitudeLines = 10;
		layersToRemove = 2;
		smallLayersToRemove = 1;
		coneHeight = 200;
		coneRadius = 300;
		smallConeHeight = coneHeight / 1.1;
		smallConeRadius = coneRadius / 1.2;
	}
	calculateConePoints();
	calculateSmallConePoints();
}

function drawConePoint(x, y, size) {
	ctxCone.beginPath();
	ctxCone.arc(x, y, size, 0, Math.PI * 2);
	ctxCone.fill();
}

function renderCone() {
	ctxCone.clearRect(0, 0, canvasCone.width, canvasCone.height);
	ctxCone.fillStyle = 'white';

	function renderConePoints(pointsArray, heightOffset) {
		for (const point of pointsArray) {
			const rotatedX = point.x * Math.cos(angleCone) - point.z * Math.sin(angleCone);
			const rotatedZ = point.x * Math.sin(angleCone) + point.z * Math.cos(angleCone);

			const perspective = 900 / (900 + rotatedZ);
			const screenX = (rotatedX * perspective) + canvasCone.width / 2;
			const screenY = (point.y * perspective) + canvasCone.height / 2 - heightOffset / 2;

			if (screenX > 0 && screenX < canvasCone.width) { // Ensure points don't go off screen horizontally
				const pointSize = baseConePointRadius * perspective;
				drawConePoint(screenX, screenY, pointSize);
			}
		}
	}

	renderConePoints(pointsCone, coneHeight);
	renderConePoints(smallPointsCone, smallConeHeight);

	angleCone += rotationConeSpeed;
	requestAnimationFrame(renderCone);
}

window.addEventListener('resize', resizeConeCanvas);
resizeConeCanvas();
renderCone();
